import React from "react";
import "./Timeline.css";
import timelineData from "../../data/Timeline/timelineData.json"; // Import the JSON data

const Timeline = () => {
  const endYear = new Date().getFullYear();

  return (
    <div className="timeline-page">
      {/* Header Section */}
      <header className="timeline-header">
        <h1>Estimate Timeline of Swaleh</h1>
        <p className="timeline-subheader">
          The goal is to do as much as I can before I can no longer
        </p>
      </header>

      {/* Timeline Section */}
      <div className="timeline-container">
        <div className="timeline-bar">
          {timelineData.map((event, index) => (
            <div
              key={index}
              className={`timeline-event ${
                event.year === endYear ? "current" : ""
              }`}
              style={{ left: `${(index / (timelineData.length - 1)) * 100}%` }}
            >
              <div className="timeline-dot"></div>
              <div className="timeline-year">{event.year}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
